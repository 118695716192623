@import url("https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

#page {
  display: flex;
  flex-direction: row;
}

#edit {
  display: flex;
  flex-direction: column;
  padding: 1vw;
  width: 30%;
  margin-top: 20px;
}

#edit div {
  display: flex;
  flex-direction: column;
}

#edit textarea {
  resize: vertical;
  min-height: 150px;
}

#preview {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1vw;
  font-family: "Bitter", serif;
  font-size: 20px;
  background-color: #ede5c1;
}

#preview h2 {
  font-size: 1.5em;
}

#preview h1 {
  font-size: 2em;
}

img {
  width: 50%;
  aspect-ratio: 1/1;
}

#saveButton {
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 50px;
}

#saveButton img {
  width: 100%;
  height: 100%;
}
